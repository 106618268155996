.hservices-list {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}
.list-container {
  position: relative;
  margin: 0 20px;
  width: 30%;
  height: auto;
  padding: 20px;
  border: 1.5px solid #e8f1f5;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.list-container:hover {
  border-color: #27496d;
  /* background: none; */
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.096);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.096);
}
.list-container img {
  position: absolute;
  top: -8%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100px;
  height: auto;
  margin: 20px auto;
  padding: 20px;
}

.list-title {
  font-size: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  padding-top: 40px;
  white-space: pre-line;
  line-height: 27px;
  color: #282d32;
}
.list-text {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  margin: 10px 0 20px 0;
  padding: 0 10px;
  font-family: "Commissioner", sans-serif;
}

/* From cssbuttons.io by @alexmaracinaru */
.cta {
  border: none;
  background: none;
  margin: 10px 0;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 2px;
  font-size: 12px;
  padding-right: 15px;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
  color: #000000;
  opacity: 0.7;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.7;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 1280px) {
  .list-text {
    font-size: 17px;
    padding: 0 5px;
  }
}

@media screen and (max-width: 500px) {
  .hservices-list {
    flex-direction: column;
  }
  .list-container {
    width: 95%;
    margin: 40px 0;
  }
  .list-container:first-child {
    margin: 0px 0 40px 0;
  }
  .list-title {
    white-space: normal;
    font-size: 19px;
    margin-bottom: 0;
  }
  .list-text {
    font-size: 16px;
    margin: 10px;
  }
}
