@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,200italic,300,300italic,regular,italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Cinzel+Decorative:regular,700,900);
@import url(https://fonts.googleapis.com/css?family=Outfit:100,200,300,regular,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:regular,500,600,700,800,900,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=News+Cycle:regular,700);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arima+Madurai:wght@200;300;400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&display=swap');

html {
  scroll-behavior: auto !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

/* ::-webkit-scrollbar {
  width: 11px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #eeeeee;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #4a4d50;
  border-radius: 7px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #565a5f;
  border-radius: 7px;
} */

/* Utility styles  */
.header {
  width: 100%;
  height: auto;
  padding-top: 150px;
  position: relative;
  z-index: -1;
}

.header-title {
  width: 100%;
  font-family: "Commissioner", sans-serif;
  font-size: 80px;
  font-weight: 500;
  color: #080808;
  text-align: center;
  opacity: 0.8;
  letter-spacing: -2px;
  padding-bottom: 35px;
}

.header-image {
  position: relative;
  width: 100%;
  height: auto;
}
.header-image::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  z-index: -1;
}

.header-image img {
  display: block;
  width: 70%;
  height: auto;
  margin: auto;
}
.header-content {
  margin-bottom: 30px;
}

@media screen and (max-width: 1280px) {
  .header {
    padding-top: 130px;
  }
  .header-title {
    font-size: 70px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    padding-top: 150px;
  }
  .header-title {
    font-size: 50px;
    font-weight: 500;
    padding-bottom: 20px;
  }
  .header-image img {
    display: block;
    width: 90%;
    height: auto;
    margin: auto;
  }
}

@media screen and (max-width: 420px) {
  .header-title {
    font-size: 45px;
  }
}
