textarea {
  resize: none;
}

.formclass {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {
  .formclass {
    width: 100%;
    max-width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    align-items: center;
  }
}

/* Input form stylings */
/* .group {
    position: relative;
} */

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  font-family: "Poppins", sans-serif !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root,
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #3d4756 !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
.css-5xn5lu-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled)::before,
.css-5xn5lu-MuiInputBase-root-MuiInput-root::after,
.css-5xn5lu-MuiInputBase-root-MuiInput-root.Mui-focused::after,
.css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid #3d4756 !important;
}
.css-1480iag-MuiInputBase-root-MuiInput-root:before,
.css-5xn5lu-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #00000040 !important;
}
.css-cio0x1-MuiInputBase-root-MuiFilledInput-root,
.css-1kuvi62-MuiInputBase-root-MuiFilledInput-root {
  background-color: #ebeff7ab !important;
}

.Send-button {
  font-family: "Lato";
  font-size: 18px;
  background: #3d4756;
  color: white;
  padding: 0.7em 3em;
  padding-left: 2.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
}

.Send-button:hover {
  background: #3d4756;
}

.Send-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.2s ease-in-out;
}

.Send-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.2s ease-in-out;
}

.Send-button:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.Send-button:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.Send-button:hover span {
  transform: translateX(10em);
}

.Send-button:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-i44wyl {
  width: 100% !important;
}

.css-68u1dt-MuiInputBase-root-MuiInput-root {
  width: 100% !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid #3d4756b4 !important;
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid #3d4756b4 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #3d4756 !important;
}

@media (max-width: 769px) {
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }
}
