.HAbout {
  width: 100%;
  margin: auto;
  height: auto;
  padding: 70px 0;
  background: url("https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80")
    bottom;
  margin: 60px 0 20px 0;
}

.HAbout-container {
  background-color: #27496dec;
  margin: auto;
  padding: 70px 150px;
  max-width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HAbout-text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HAbout-text h1 {
  font-family: "Commissioner", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.HAbout-text p {
  font-family: "Commissioner", sans-serif;
  font-size: 23px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
  margin-top: 5px;
  text-shadow: 2px 2px 20px #27496d;
}

.jot {
  position: relative;
  color: white;
  font-size: 15px;
  font-family: "Nunito", serif;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 10px 20px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 1;
}

.jot::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: white;
  z-index: -1;
  transition: all 0.2s;
}

.jot:hover::before {
  width: 100%;
}

.jot:hover {
  color: black;
}

.jot:active:before {
  background: #b9b9b9;
}
/*  */

@media screen and (max-width: 1350px) {
  .HAbout-container {
    padding: 50px 100px;
  }
}

@media screen and (max-width: 946px) {
  .HAbout-container {
    padding: 50px 80px;
  }
}

@media screen and (max-width: 711px) {
  .HAbout-container {
    padding: 50px 50px;
  }
}

@media screen and (max-width: 527px) {
  .HAbout {
    padding: 40px 0;
    margin: 0px 0 20px 0;
  }
  .HAbout-container {
    max-width: 90%;
    padding: 50px 30px;
  }

  .HAbout-text p {
    font-size: 17px;
  }
}

@media screen and (max-width: 430px) {
  .HAbout-container {
    padding: 30px;
  }

  .HAbout-text h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .HAbout-text p {
    font-size: 16px;
    text-align: center;
  }
  .jot {
    position: relative;
    color: white;
    font-size: 15px;
    font-family: "Nunito", serif;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 8px 15px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 350px) {
  .HAbout-text p {
    font-size: 15px;
    text-align: center;
  }
}
