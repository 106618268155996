.career {
    width: 100%;
    max-width: 100%;
    margin: auto;
  }
  
  .career-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto;
    max-width: 70%;
  }
  .grow-container {
    position: relative;
    margin: 0 20px;
    width: 100%;
    height: auto;
    padding: 40px;
    border: 1.5px solid #d8eacc;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .grow-container:hover {
    border-color: #949c8e;
    /* background: none; */
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.096);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.096);
  }
  
  .apply-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .grow-container h1 {
    width: 100%;
    font-family: "Commissioner", sans-serif;
    font-size: 40px;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 15px;
  }
  
  .grow-container p {
    width: 100%;
    font-family: "Commissioner", sans-serif;
    color: #000000;
    opacity: 0.8;
    font-size: 22px;
    font-weight: 400;
    white-space: pre-line;
  }
  
  .apply-form {
    width: 100%;
    height: auto;
  }
  .cv-btn {
    /* Variables */
    --button_radius: 0.75em;
    --button_color: #ffffff;
    --button_outline_color: #2e2e2e;
    font-size: 17px;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
    margin-top: 20px;
  }
  
  .button_cv {
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 2em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }
  
  .cv-btn:hover .button_cv {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
  }
  
  .cv-btn:active .button_cv {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }
  .dialogtitlee {
    font-size: 30px !important;
    font-family: "Commissioner", sans-serif !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    padding-bottom: 5px !important;
  }
  
  .dialogdes {
    margin: 0 0 20px 0 !important;
    font-family: "Commissioner", sans-serif !important;
    font-size: 17px !important;
  }
  
  .css-button-sharp--black {
    color: #fff;
    padding: 5px 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    font-family: "Commissioner", sans-serif;
    font-size: 15px;
    outline: none;
    border: 2px solid #212529;
    background: #212529;
  }
  .css-button-sharp--black:hover {
    background: #fff;
    color: #212529;
  }
  .close-btn {
    outline: none;
    background: none;
    border: none;
    font-family: "Commissioner", sans-serif;
    font-size: 15px;
    padding: 5px 10px;
    font-weight: bold;
  }

  
  @media screen and (max-width: 500px) {
    .form-label {
      font-size: 13px;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
      margin: 15px !important;
    }
    .dialogdes {
      font-size: 15px !important;
      line-height: 22px !important;
    }
    .action-btn {
      padding: 20px 0 0 0 !important;
    }
    .dialogtitlee {
      padding-bottom: 5px !important;
      padding-right: 0px !important;
      font-size: 25px !important;
    }
    .career-content {
      margin: 0 auto 50px auto;
      max-width: 90%;
    }
    .grow-container {
      padding: 40px 15px;
    }
    .grow-container h1 {
      font-size: 30px;
      font-weight: 600;
    }
    .grow-container p {
      font-size: 17px;
    }
  }
  
  /* .job-container {
    background: #e8e6d6;
    padding: 15px 100px 15px 10px;
    border-radius: 8px;
    border-left: 6px solid #53816f8f;
    margin-bottom: 20px;
  }
  
  .job-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    opacity: 0.7;
    margin-left: 10px;
  }
  
  .job-place {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    color: #000000;
    opacity: 0.5;
    margin-left: 10px;
  } */
  
  /* @media screen and (max-width: 1352px) {
    .career-image img {
      top: 240px;
    }
  
    .career-content {
      padding-top: 250px;
      margin: 60px 30px;
    }
  } */
  
  /* @media screen and (max-width: 1250px) {
    .career-image img {
      top: 220px;
    }
  
    .career-content {
      padding-top: 220px;
      margin: 60px 30px;
    }
  } */
  /* 
  @media screen and (max-width: 1050px) {
    .career-image img {
      top: 200px;
    }
  
    .career-content {
      padding-top: 170px;
      margin: 60px 30px;
    }
  }
  
  @media screen and (max-width: 950px) {
    .career-image img {
      top: 170px;
    }
  
    .career-content {
      padding-top: 120px;
      margin: 60px 30px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .career-title {
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
    }
  
    .career-image img {
      top: 130px;
      width: 80%;
    }
  
    .career-content {
      padding-top: 70px;
      margin: 60px 30px;
    }
  }
  
  @media (max-width: 680px) {
    .career-header {
      height: 50vh;
    }
  
    .career-title {
      top: 38%;
    }
  
    .career-image img {
      width: 90%;
      top: 170px;
    }
  
    .career-content {
      padding-top: 170px;
      margin: 60px 30px;
    }
  
    .apply-content h2 {
      font-size: 50px;
    }
  
    .apply-content p {
      font-size: 17px;
      line-height: 27px;
    }
  }
  
  @media (max-width: 500px) {
    .career-header {
      height: 40vh;
    }
  
    .career-title {
      top: 45%;
      font-size: 40px;
    }
  
    .career-image img {
      width: 90%;
      top: 140px;
    }
  
    .career-content {
      padding-top: 120px;
      margin: 60px 20px;
    }
  
    .apply-content h2 {
      font-size: 40px;
    }
  
    .apply-content p {
      font-size: 15px;
      line-height: 23px;
      margin-top: 5px;
      white-space: normal;
    }
  }
  
  @media (max-width: 450px) {
    .career-content {
      padding-top: 90px;
    }
  }
  
  @media (max-width: 370px) {
    .career-content {
      padding-top: 80px;
    }
  } */
  