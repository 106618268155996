.about-content {
  max-width: 70%;
  margin: 30px auto 50px auto;
  display: flex;
}
.about-list {
  display: grid;
  gap: 50px;
  grid-template-areas: "header header";
}
.about-container:first-child {
  grid-area: header;
}
.about-subtitle {
  font-size: 35px;
  font-family: "Lato", sans-serif;
  font-weight: 600;
}
.about-text {
  font-size: 20px;
  line-height: 33px;
  font-family: "Commissioner", sans-serif;
  color: #000000;
  opacity: 0.7;
}

@media screen and (max-width: 500px) {
  .about-content {
    max-width: 90%;
    margin: 0px auto 50px auto;
  }
  .about-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .about-subtitle {
    font-size: 30px;
  }
  .about-text {
    font-size: 18px;
    line-height: 28px;
  }
}
