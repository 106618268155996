.contact {
  width: 100%;
  height: auto;
}
.contact-container {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
}
.contact-container::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 40%;
  left: 0;
  top: 0;
  background-color: #cad0da;
  z-index: -1;
}
