.HService {
  padding: 70px 0 50px 0;
  /* color: #f2f7fa; */
}

.HServices-heading {
  width: 100%;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HServices-title {
  width: 100%;
  font-family: "Commissioner", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #27496d;
  max-width: 90%;
  /* text-transform: uppercase; */
}

.HServices-subtitle {
  width: 100%;
  max-width: 90%;
  font-family: "Poppins", sans-serif;
  color: #000000;
  opacity: 0.8;
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  white-space: pre-line;
}

.services-container {
  max-width: 85%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
  margin-bottom: 30px;
}

@media (max-width: 769px) {
  .HServices-subtitle {
    font-size: 23px;
    line-height: 32px;
  }
  .HServices-heading {
    max-width: 95%;
  }

  .services-container {
    max-width: 95%;
    padding-top: 0;
  }
}

@media (max-width: 500px) {
  .HService {
    padding: 70px 0 20px 0;
    /* color: #f2f7fa; */
  }
  .HServices-subtitle {
    font-size: 22px;
    line-height: 32px;
    width: 100%;
    white-space: normal;
    margin-top: 5px;
  }
  .services-container {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 430px) {

  .HServices-subtitle {
    font-size: 19px;
    line-height: 30px;
    width: 100%;
  }
}
