.form-container {
  width: 100%;
  height: 500px;
  max-width: 65%;
  margin: auto;
  display: flex;
  justify-content: center;
  background: #ffffff;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #00000025;
}

.container-left {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d4756;
  padding: 0 10px;
  border-radius: 10px;
  border: 10px solid #0000001c;
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  margin-left: 20px;
}

.left1 {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.icons {
  color: #eeeeee;
  display: grid;
  grid-row: 2;
  align-items: center;
  grid-template-columns: 10% 90%;
}

.icons svg {
  font-size: 23px;
}

.left1 a {
  color: #ebebeb;
  font-size: 20px;
  font-family: "Poppins";
  margin-left: 5px;
  margin-bottom: 0 !important;
}
.left1 a:hover {
  color: #ffffff;
}

.left3 {
  display: flex;
}

.left3 a {
  margin-right: 25px;
  color: #c7c7c7;
  text-decoration: none;
  font-size: 18px;
  border: 1px solid #c7c7c7;
  padding: 9px;
  border-radius: 100px;
  line-height: 10px;
}

.left3 a:hover {
  color: #ffffff;
  opacity: 0.9;
}

.container-right {
  width: 100%;
  height: auto;
  margin: 40px;
}

@media (max-width: 500px) {
  .form-container {
    width: 100%;
    height: auto;
    max-width: 90%;
    flex-direction: column;
  }
  .container-left {
    width: 100%;
    height: 350px;
    padding: 0 10px;
  }
  .left-content {
    gap: 40px;
  }
  .left1 {
    max-width: 100%;
    gap: 30px;
  }
  .left1 a {
    color: #ebebeb;
    font-size: 16px;
    font-family: "Poppins";
    margin-left: 5px;
    margin-bottom: 0 !important;
  }
  .icons svg {
    font-size: 18px;
  }
  .left3 a {
    margin-right: 25px;
    color: #c7c7c7;
    text-decoration: none;
    font-size: 15px;
    padding: 7px;
    line-height: 8px;
  }
}

/* 
@media (max-width: 769px) {
  .contact-header {
    height: 60vh;
    margin-bottom: 850px;
  }

  .contact-title {
    font-size: 50px;
    top: 36%;
  }

  .form-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    max-width: 60%;
    width: 100%;
    height: auto;
  }

  .container-left {
    width: 100%;
    height: auto;
    padding: 50px 0;
  }

  .left-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    gap: 40px;
  }

  .left1 {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .left1 p {
    color: #f3f3f3;
    font-size: 16px;
    font-family: "Poppins";
    margin-left: 10px;
    margin-bottom: 0 !important;
  }

  .left3 {
    display: flex;
  }

  .container-right {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
  }
}

@media (max-width: 500px) {
  .contact-header {
    height: 60vh;
    margin-bottom: 750px;
  }
  .form-container {
    max-width: 90%;
  }
  .contact-title {
    font-size: 40px;
    top: 36%;
  }
  .icons svg {
    font-size: 21px;
  }

  .left1 a {
    font-size: 18px;
  }
  .left3 a {
    margin-right: 20px;
    color: #c7c7c7;
    text-decoration: none;
    font-size: 16px;
  }
}

@media (max-width: 380px) {
  .icons svg {
    font-size: 21px;
  }

  .left1 a {
    font-size: 18px;
  }
} */
